export const touchStart = (ref, className) => {
	if (ref === undefined) return;
	else {
		ref.current.classList.add(className);
	}
};
export const touchEnd = (ref, className) => {
	if (ref === undefined) return;
	else {
		ref.current.classList.remove(className);
	}
};

export const email = 'pieceofmood@naver.com';
export const refundPolicyLink =
	'https://docs.google.com/document/d/1CrOBYnP8BpZh-Q3Jr5D-BCWDx7P1-h7odEOinj0jqF0/edit?usp=sharing';

export const getProvisionForm = (type, consultingForm, consultingCount) =>
	type === 'PDF' ? '문서 스트리밍' : `${consultingForm} ${consultingCount}회`;
