import styled from 'styled-components';

import cssMixin from '@src/style';

export type FlexRowProps = {
	mb?: number;
	mr?: number;
};

const FlexRow = styled.div<FlexRowProps>`
	${cssMixin.flexRow};
	margin-bottom: ${({ mb }) => mb && mb / 100 + 'rem'};
	margin-right: ${({ mr }) => mr && mr / 100 + 'rem'};
`;

export default FlexRow;
