import React from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';
import { Color } from '.';
import CheckIcon from '../icons/common/check';

export type CheckProps = {
	value: boolean;
	setValue: React.Dispatch<React.SetStateAction<boolean>>;
	style?: React.CSSProperties;
};

export default function SignInCheck({ value, setValue, style }: CheckProps) {
	const handleClick = () => {
		setValue(!value);
	};
	const IconColor = value ? '#ffd000' : Color.GRAY400;

	return (
		<Wrappper onClick={handleClick} style={style}>
			<CheckIcon fill={IconColor} width="0.12rem" height="0.12rem"></CheckIcon>
		</Wrappper>
	);
}
const Wrappper = styled.div`
	${cssMixin.center};
	width: 0.24rem;
	height: 0.24rem;
	margin-right: 0.06rem;
`;
