import styled, { css } from 'styled-components';

import Color from './color';
import cssMixin from '../../style';
import { ThemeType } from '@src/types/theme';
import { BreakPointEnum, BreakPointLabel } from '@src/theme/media';
import { getRemSize } from '@src/lib/util/number-parser';

export type PProps = {
	level?: number;
	pxSize?: number;
	color?: Color | string;
	width?: string;
	height?: string;
	fontWeight?: number | string;
	mb?: number;
	mr?: number;
	isEnNum?: boolean;
	onClick?: () => void;
	textAlign?: string;
	ellipsis?: boolean;
	preWrap?: boolean;
	pre?: boolean;
	lineHeight?: number;
	minNumOfLines?: number;
	numOfLines?: number;
	letterSpacing?: string;
	hideBreakPoint?: BreakPointLabel;
	textDecoration?: 'none' | 'line-through' | ' overline' | 'underline' | ' initial' | ' inherit';
	fontSizes?: number[];
	mbs?: number[];
};

const P = styled.p.attrs((props: PProps) => ({
	level: props.level || 0,
	lineHeight: props.lineHeight || 1.25,
	letterSpacing: props.letterSpacing || '0.5px',
	numOfLines: props.numOfLines || 1,
	minNumOfLines: props.minNumOfLines || 1,
}))<PProps>`
	font-size: ${props => (props.pxSize ? props.pxSize + 'px' : 0.14 + props.level * 0.02 + 'rem')};
	font-weight:${props => props.fontWeight && props.fontWeight};
	font-family:${props => props.isEnNum && 'Roboto'};
	color: ${props => (props.color ? props.color : Color.DEFAULT_BLACK)};
	width: ${props => props.width || 'fit-content'};
	height: ${props => props.height || 'fit-content'};
	text-align: ${props => props.textAlign && props.textAlign};
	text-decoration:${props => props.textDecoration};
	line-height:${props => props.lineHeight && props.lineHeight};
	letter-spacing:${props => (props.isEnNum ? 'normal' : props.letterSpacing)};
	margin-bottom:${({ mb }) => mb && mb / 100 + 'rem'};
	margin-right:${({ mr }) => mr && mr / 100 + 'rem'};
	word-break: break-all;
	${props => props.ellipsis && cssMixin.ellipsis}
	${props => props.preWrap && cssMixin.preWrap}
	${props => props.pre && 'white-space:pre'};
	${props =>
		props.numOfLines > 1 &&
		css`
			white-space: normal;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: ${props.numOfLines};
		`}

	${(props: { theme: ThemeType; hideBreakPoint?: BreakPointLabel }) =>
		props.hideBreakPoint &&
		props.theme.breakpoint[props.hideBreakPoint]`
		display:none;
	`}
	${({ level, minNumOfLines, lineHeight }) =>
		minNumOfLines > 1 &&
		`	
		min-height:${(0.14 + level * 0.02) * minNumOfLines * lineHeight}rem;
	`}

	${({ fontSizes, theme, minNumOfLines, lineHeight }) =>
		fontSizes &&
		fontSizes.map(
			(fontSize, index) => theme.breakpoint[BreakPointEnum[index]]`
			font-size:${getRemSize(fontSize)};
			min-height:${minNumOfLines > 1 && getRemSize(fontSize * minNumOfLines * lineHeight)};
		`,
		)}

	${({ mbs, theme }: { mbs?: number[]; theme: ThemeType }) =>
		mbs &&
		mbs.map(
			(mb, index) => theme.breakpoint[BreakPointEnum[index]]`
			margin-bottom:${getRemSize(mb)};
		`,
		)}

`;
export default P;
