import { css } from 'styled-components';
import Color from '@src/components/atoms/color';

const Default = css`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0;
	margin: 0;

	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
`;

const signIn = css<{ provider: string }>`
	${Default}
	width: 383px;
	height: 49px;
	border-radius: 4px;
	${({ provider }) =>
		provider === 'Google' &&
		`background-color: white;`};
	${({ provider }) =>
		provider === 'Naver' &&
		`background-color: white;`};
	${({ provider }) =>
		provider === 'Kakao' &&
		`background-color: #ffd000;`};
	${({ theme }) => theme.tablet`
		width:100%;
		margin: 0 8%;
	`}
`;

const signInText = css`
	font-size: 0.16rem;
	font-weight: 500;
	color: white;
`;

const shadowRound = css`
	border-radius: 999px;
	box-shadow: 0 0 16px 0 rgba(173, 173, 173, 0.5);
`;

const hover = css<{ bgColor?: string; hColor?: string }>`
	&:hover {
		background-color: ${props => (props.bgColor ? props.bgColor : Color.PRIMARY)};
		color: 'white';
		transition: 0.3s;
		border: none;
		p {
			color: ${props => (props.hColor ? props.hColor : 'white')};
		}
		svg path {
			fill: ${props => (props.hColor ? props.hColor : 'white')};
		}
	}
`;
const active = css`
	&:active {
		background-color: ${Color.PRIMARY};
		color: white;
		transition: 0.3s;
	}
`;

const buttonStyle = { Default, signIn, signInText, shadowRound, hover, active };

export default buttonStyle;
