import { IconProps } from '@src/types';
import React from 'react';

export default function CheckIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 515.556 515.556" {...props}>
			<path d="M0 274.226l176.549 176.886L515.556 112.44l-48.67-47.997-290.337 290L47.996 225.891z" />
		</svg>
	);
}
