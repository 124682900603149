import React, { useRef } from 'react';
import styled from 'styled-components';

import { touchStart, touchEnd } from '@src/lib/util';

export type TouchBoxProps = {
	touchEffect?: string;
	children?: any;
	bgColor?: string;
	style?: React.CSSProperties;
};

export default function TouchBox({
	touchEffect = 'touched',
	bgColor = 'white',
	style,
	children,
}: TouchBoxProps) {
	const ref = useRef(null);
	return (
		<_TouchBox
			ref={ref}
			onTouchStart={touchStart.bind(this, ref, touchEffect)}
			onTouchEnd={touchEnd.bind(this, ref, touchEffect)}
			bgColor={bgColor}
			style={style}
		>
			{children}
		</_TouchBox>
	);
}

const _TouchBox = styled.div<{ bgColor: string }>`
	background-color: ${props => props.bgColor};
	cursor: pointer;
`;
