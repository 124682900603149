import styled from 'styled-components';

type LineProps = {
	direction?: 'ROW' | 'COL';
	length?: number;
	perLength?: string;
	level?: number;
	size?: number;
	color?: string;
};

const Line = styled.div<LineProps>(
	({ direction = 'COL', length, perLength, size, level = 0, color = '#a8aeb3' }: LineProps) => {
		if (direction === 'COL') {
			return {
				width: perLength ? perLength : length ? `${length * 0.01}rem` : '100%',
				height: size ? `${size * 0.01}rem` : `${0.01 + 0.03 * level}rem`,
				backgroundColor: color,
			};
		}
		if (direction === 'ROW') {
			return {
				width: size ? `${size * 0.01}rem` : `${0.01 + 0.03 * level}rem`,
				height: perLength ? perLength : length ? `${length * 0.01}rem` : '100%',
				backgroundColor: color,
			};
		}
	},
);

export default Line;
