import styled from 'styled-components';
import { Color } from '.';

export type TextInputProps = {
	width?: string | number;
	height?: string | number;
	level?: number;
};

const TextInput = styled.input.attrs((props: TextInputProps) => ({
	width: '100%',
	level: 0,
}))<TextInputProps>`
	width: ${props => props.width};
	height: fit-content;
	padding: 0.12rem 0.16rem;
	font-size: ${props => 0.14 + props.level * 0.02 + 'rem'};
	font-weight: 400;
	line-height: 1;
	border: 1px solid ${Color.GRAY500};
	border-radius: 2px;
`;

export default TextInput;
