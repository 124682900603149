enum Color {
	BLACK = '#333',
	WHITE = '#fff',
	PRIMARY = '#eb4848',
	SECONDARY = '#303030',
	DEFAULT_BLACK = '#1C1C1C',
	BACKGROUND_DEEP_DARK_GREY = '#f8f8f8',
	BACKGROUND_DARK_GERY = '#f1f1f1',
	BACKGROUND_LIGHT_GREY = '#f4f4f4',
	CARD_INFO_BACKGROUND = '#fbfbfb',
	BORDER_GERY = '#ddd',
	BACKGROUND_BLACK = '#272727',
	MEDIUM_BLACK = '#0d0d0d',
	GREY = '#a8aeb3',
	GREY_1 = '#858585',
	TEXT_GREY = '#5d5d5d',
	BANNER_YELLOW = '#ffe8b0',
	DISABLED = '#dedede',
	PURPLE = '#595bfd',
	LIGHT_PURPLE = '#f6f6ff',
	BANNER_BLACK = '#404040',
	GRAY900 = '#1C1C1C',
	GRAY800 = '#3E4042',
	GRAY700 = '#838383',
	GRAY600 = '#9B9B9B',
	GRAY500 = '#C2C2C2',
	GRAY400 = '#DADADA',
	GRAY300 = '#E6E6E6',
	GRAY200 = '#F7F7F7',
	GRAY100 = '#FFFFFF',
}

export default Color;
