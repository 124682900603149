import React from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';

export type LabelProps = {
	bgColor?: string;
	color?: string;
	text?: string;
	textSize?: number;
	top?: number;
	left?: number;
	right?: number;
};

type WrapperProps = {
	bgColor: string;
	color: string;
	textSize?: number;
	top?: number;
	left?: number;
	right?: number;
};

export default function Label({ bgColor, color, text, top, left, right, textSize }: LabelProps) {
	return <Wrapper {...{ bgColor, color, top, left, right, textSize }}>{text}</Wrapper>;
}

const Wrapper = styled.span<WrapperProps>`
	${cssMixin.center};
	padding: 0.07rem 0.11rem 0.06rem;
	font-size: 0.12rem;
	font-weight: bold;
	letter-spacing: normal;
	position: absolute;
	width: auto;
	height: auto;
	top: 0.08rem;
	right: 0.08rem;
	z-index: 2;
	border-radius: 4px;
	${props => props.bgColor && `background-color: ${props.bgColor};`};
	${props => props.color && `color: ${props.color};`};
	${props => props.top && `top:${props.top / 100}rem`};
	${props => props.left && `left:${props.left / 100}rem`};
	${props => props.right && `right:${props.right / 100}rem`};
	${props => props.textSize && `font-size:${props.textSize / 100}rem`};
`;
