import styled from 'styled-components';

import cssMixin from '@src/style';

export type FlexColProps = {
	mb?: number;
	mr?: number;
};

const FlexCol = styled.div<FlexColProps>`
	${cssMixin.flexCol};
	margin-bottom: ${({ mb }) => mb && mb / 100 + 'rem'};
	margin-right: ${({ mr }) => mr && mr / 100 + 'rem'};
`;

export default FlexCol;
