import styled from 'styled-components';

import { Color } from '.';
import { getRemSize } from '@src/lib/util/number-parser';

export type ImgProps = {
	width?: number;
	height?: number;
	br?: boolean;
	brWidth?: number;
	brColor?: string;
	src: string;
	alt?: string;
	cover?: boolean;
	mb?: number;
	mr?: number;
	mt?: number;
	round?: boolean;
};

const Img = styled.img.attrs((props: ImgProps) => ({
	brWidth: props.brWidth || 1,
	brColor: props.brColor || Color.BORDER_GERY,
}))<ImgProps>`
	width: ${({ width }) => (width ? getRemSize(width) : 'fit-content')};
	height: ${({ height }) => (height ? getRemSize(height) : 'fit-content')};
	border: ${({ br, brColor, brWidth }) => br && `${brWidth}px solid ${brColor}`};
	border-radius: ${({ round }) => round && `999px`};
	object-fit: ${({ cover }) => (cover ? 'cover' : 'contain')};
	margin-bottom: ${({ mb }) => mb && getRemSize(mb)};
	margin-right: ${({ mr }) => mr && getRemSize(mr)};
	margin-top: ${({ mt }) => mt && getRemSize(mt)};	
`;

export default Img;
