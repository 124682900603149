import React from 'react';
import styled from 'styled-components';

export type ResBoxProps = {
	ratio: number;
	children: any;
	style?: React.CSSProperties;
};

export default function ResBox({ ratio = 1, children, style }: ResBoxProps) {
	return (
		<Wrapper ratio={ratio} style={style}>
			<Div>{children}</Div>
		</Wrapper>
	);
}

const Wrapper = styled.div<{ ratio: number }>`
	position: relative;
	width: 100%;
	height: 0;
	padding-top: ${({ ratio }) => `${100 * ratio}%`};
`;

const Div = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	width: 100%;
	height: 100%;
`;
