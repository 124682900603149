import React from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';
import { Color } from '.';
import CheckBoxIcon from '../icons/common/checkbox';

export type CheckBoxProps = {
	value: boolean;
	setValue: React.Dispatch<React.SetStateAction<boolean>>;
	style?: React.CSSProperties;
};

export default function CheckBox({ value, setValue, style }: CheckBoxProps) {
	const handleClick = () => {
		setValue(!value);
	};
	const IconColor = value ? '#FF4E4E' : Color.GRAY400;

	return (
		<Wrappper onClick={handleClick} style={style}>
			<CheckBoxIcon fill={IconColor} width="0.2rem" height="0.2rem"></CheckBoxIcon>
		</Wrappper>
	);
}
const Wrappper = styled.div`
	${cssMixin.center};
	width: 0.24rem;
	height: 0.24rem;
	margin-right: 0.06rem;
`;
