import * as React from 'react';

import { IconProps } from '@src/types';

export default function CheckBoxIcon(props: IconProps) {
	const { width, height, fill, style } = props;
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			{...{ width, height, style }}
		>
			<path
				d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10zm-.016-2H10a8 8 0 10-.016 0zM8 15l-4-4 1.41-1.41L8 12.17l6.59-6.59L16 7l-8 8z"
				fill={fill}
			/>
		</svg>
	);
}
