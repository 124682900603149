export const addCommaToNumber = (num: number) => {
	const numString = num.toString();
	let result = '';
	let i = 0;
	for (; i < numString.length - 1; i++) {
		result += numString[i];
		if ((numString.length - i - 1) % 3 == 0) result += ',';
	}
	result += numString[i];
	if (result === '0') {
		return 0;
	}
	return result;
};

export const addDashToPhoneNumber = (value: string | number) => {
	const number = value.toString().replace(/[^0-9]/g, '');

	const secondIndex = number.length > 10 ? 7 : 6;
	const result = [
		number.slice(0, 3),
		number.slice(3, secondIndex),
		number.slice(secondIndex, number.length),
	]
		.filter(value => value != '')
		.join('-');
	return result.slice(0, 13);
};

export const removeDashFromPhoneNumber = (value: string) => {
	return value.split('-').join('');
};

export const getRemSize = (value: number) => {
	return value / 100 + 'rem';
};
