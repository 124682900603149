import styled from 'styled-components';
import { PProps } from './p';

type StrongProps = Pick<
	PProps,
	'pxSize' | 'level' | 'fontWeight' | 'color' | 'textDecoration' | 'mr' | 'isEnNum'
>;

const Strong = styled.strong<StrongProps>`
	font-size: ${props => (props.pxSize ? props.pxSize + 'px' : 0.14 + props.level * 0.02 + 'rem')};
	font-weight: ${props => props.fontWeight};
	color: ${props => props.color};
	text-decoration: ${props => props.textDecoration};
	margin-right: ${props => props.mr && `${props.mr / 100}rem`};
	font-family: ${props => props.isEnNum && 'ROBOTO'};
`;

export default Strong;
