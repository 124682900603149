import React from 'react';
import styled from 'styled-components';

import { ResImg } from '.';
import Label from './label';
import { ResImgProps } from './responsive-img';

export type ThumbnailProps = ResImgProps & { setLabel?: boolean };

export default function Thumbnail({
	ratio,
	src,
	alt,
	collapse,
	setLabel,
	lazyLoad,
}: ThumbnailProps) {
	return (
		<Wrapper>
			{setLabel && <Label bgColor="#FF003D" color="white" text="무료" />}
			<ResImg {...{ ratio, src, alt, collapse, lazyLoad }} />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	position: relative;
`;
