import React from 'react';
import styled from 'styled-components';

import buttonStyle from '@src/style/button';

type ButtonProps = {
	level?: number;
	width?: string | number;
	height?: string | number;
	color?: string;
	backgroundColor?: string;
	dColor?: string;
	dBackgroundColor?: string;
	outline?: boolean;
	active?: boolean;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
	onClick?: () => void;
	children?: any;
	borderColor?: string;
	br?: string;
	mr?: number;
};

const Button = styled.button.attrs((props: ButtonProps) => ({
	level: props.level || 0,
}))<ButtonProps>`
	${buttonStyle.Default}
	background-color: ${props =>
		!props.backgroundColor ? 'transparent' : props.backgroundColor || '#fff'};
	color: ${props => (props.disabled ? '#ccc' : props.color)};
	width: ${props => props.width};
	height: ${props => props.height};
	border: ${props => (props.outline ? `1px solid ${props.borderColor}` : 'none')};
	border-radius: ${props => props.br};
	font-size: ${props => 0.16 + props.level * 0.02}rem;
	margin-right:${props => props.mr && `${props.mr / 100}rem`};	
`;

export default Button;
